import RoleNames from "./RoleNames";

const Roles = {
  ROLES1: [
    RoleNames.PHYSICIAN,
    RoleNames.PRACTICE_OWNER,
    RoleNames.SYSTEM_ADMIN,
  ],
  ROLES2: [
    RoleNames.PRACTICE_ADMIN, 
    RoleNames.GENERAL, 
    RoleNames.SYSTEM_ADMIN
  ],
  ROLES3: [
    RoleNames.PRACTICE_ADMIN,
    RoleNames.GENERAL,
    RoleNames.PHYSICIAN,
    RoleNames.PRACTICE_OWNER,
    RoleNames.SYSTEM_ADMIN,
  ],
  ROLES4: [
    RoleNames.HEALTH_NETWORK_ADMIN,
    RoleNames.GENERAL,
    RoleNames.PHYSICIAN,
    RoleNames.PRACTICE_OWNER,
    RoleNames.SYSTEM_ADMIN,
  ],
  ROLES5: [
    RoleNames.EMBASSY_LEAD,
    RoleNames.EMBASSY_MEMBER
  ]
};

export default Roles;

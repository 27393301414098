import { useState, FC, useContext, useEffect } from "react";
import PhysiciansToVerify from "../myPractice/components/PhysiciansToVerify";
import PracticePhysicians from "../myPractice/components/PracticePhysicians";
import MyPracticeInfo from "../myPractice/components/MyPracticeInfo";
import PracticeNonPhysicians from "../myPractice/components/PracticeNonPhysicians";
import PracticeDelegation from "../myPractice/components/PracticeDelegation";
import IPracticeInfo from "../../../types/IPracticeInfo";
import practiceInfoContext from '../../../context/PracticeInfoContext'
import Spinner from "../../spinner/Spinner";
import PracticeInfoState from "../../../context/PracticeInfoState";
import PracticeService from "../../../services/PracticeService";
import axios from "axios";
import { appInsights } from "../../../appInsights";
import { useOktaAuth } from "@okta/okta-react";
import Roles from "../../../config/Roles";
import useUserRole from "../../../hooks/useUserRole";
import UserService from "../../../services/UserService";
import menuItemsContext from "../../../context/MenuItemsContext";
import Loader from "../../common/Loader";
import MyEmbassyInfo from "./MyEmbassyInfo";
import EmbassyService from "../../../services/EmbassyService";
import IEmbassyDetails from "../../../types/IEmbassyDetails";



const MyEmbassy: FC<{ name?: string }> = ({ name }) => {
  // const { practiceInfo, setPracticeInfo } = useContext(practiceInfoContext);
  const [ embassyInfo, setEmbassyInfo ]= useState<IEmbassyDetails>();
  const [error, setError] = useState<Error | null>(null);
  const [apiCallCount, setApiCallCount] = useState(0);
  const { setMenuItems } = useContext(menuItemsContext);
  
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useUserRole();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [pageName, setPageName] = useState(name);

    
  useEffect(() => {
    UserService.getMenuItems(accessToken)
    .then((response) => {
      setMenuItems(response.data);
      setLoading(false);
    })
    .catch((e) => {
      if (!axios.isCancel(e)) {
        console.error(e);
        appInsights.trackException({ error: e });
        setError(e);
        setLoading(false);
      }
    });
    return () => {
      cancelToken.cancel();
    };
},
  [])

  useEffect(() => {
    setError(null)
    EmbassyService.getEmbassyInfo(accessToken, cancelToken)
      .then((response) => {
        setEmbassyInfo(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e.response.data.message });
          setError(e.response.data.message);
          setLoading(false);
        }

      });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    if (embassyInfo == null || embassyInfo == undefined) return;
    setPageName(embassyInfo?.embassyName);
  }, [embassyInfo])


  if (error !== null) {
    return (
      <div className="practice">
        <div className="container">
          <h2 className="heading-main">{pageName}</h2>
          <h5 style={{ marginLeft: 10 }}>{error + ""}</h5>
        </div>
      </div>
    );
  }
  return (
    <div className="practice">
      <div className="container">
        <h2 className="heading-main">{pageName}</h2>
        {loading ? (
              <div style={{marginLeft:'50%'}} ><Loader /> </div>
            ) : (
        <div className="row" id="chartLayoutContainer">

          {embassyInfo !== undefined ? <div className="col-md-8 col-12">
            <div className="chart">
              <MyEmbassyInfo
                practiceInfo={embassyInfo}
              />
            </div>
          </div>:null}
        </div>
            )
          }
      </div>
    </div>
  );
};

export default MyEmbassy;


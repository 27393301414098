import { useState, useEffect, FC, useCallback, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import PatientService from "../../../services/PatientService";
import PatientsList from "./components/PatientsList";
import PatientsCard from "./components/PatientsCard";
import SearchBar from "../../common/SearchBar";
import IPatient, { FutureVisit } from "../../../types/IPatient";
import usePatients from "../../../hooks/usePatients";
import useScrollPosition from "../../../hooks/useScrollPosition";
import useSessionState from "../../../hooks/useSessionState";
import LookbackFilter from "../../common/LookBackFilter";
import ILookbackOption from "../../../types/ILookbackOption";
import Loader from "../../common/Loader";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import VisitsModal from "./components/VisitsModal";
import CustomAlert from "../../common/CustomAlert";
import { appInsights } from "../../../appInsights";
import axios from "axios";
import DeleteConfirmationModal from "./components/DeleteConfirmationModal";
import IPatientQuery from "../../../types/IPatientQuery";
import CustomPagination from "../../common/CustomPagination";
import { useHistory } from 'react-router-dom'
import { min } from "moment";
import EmbassyService from "../../../services/EmbassyService";
import IEmbassyDetails from "../../../types/IEmbassyDetails";
type visits = {
  lastVisit: { dtm: string; location: string };
  futureVisits: FutureVisit[];
};

const EmbassyPatients: FC<{ name?: string }> = ({ name }) => {
  const [error, setError] = useState<Error | null>(null);
  const visitLookbackOptions: ILookbackOption[] = require("../../../data/patientListVisitsLookbackOptions.json");
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [patients, setPatients] = useState<IPatient[]>();
  const isBigScreen = window.innerWidth > 1000;
  const [flag, setFlag] = useState(isBigScreen);
  const [searchText, setSearchText] = useSessionState("viewpatientsearch_state", "");
  const [patient, setPatient] = useState<IPatient>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isBack, setIsBack] = useState<boolean>(true);
  const [pages, setPages] = useSessionState("viewpatientpages_state", 0);
  const [maxPages, setMaxPages] = useSessionState("viewpatientMaxPage_state", 0);
  const [currentPage, setCurrentPage] = useSessionState("viewpatientcurrentpage_state", 1);
  const [lookback, setLookback] = useSessionState("viewpatientlookback_state", visitLookbackOptions[0].value);
  const pageSize = 12;
  const [query, setQuery] = useState<IPatientQuery>({
    PageSize: pageSize,
    PageNumber: currentPage,
    Lookback: lookback,
    SearchText: searchText
  });
  const [searched, setSearched] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [value, setValue] = usePatients("viewpatients");
  const loader = useRef(null);
  const [viewAsFilter, setViewAsFilter] = useSessionState<"list" | "cards">("viewpatientsfilter_state", "list");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [visits, setVisits] = useState<visits>();
  const [apiCallCount, setApiCallCount] = useState(0);
  const [recentSearchPatientName, setRecentSearchPatientName] = useState<string>("");
  const [ locationKeys, setLocationKeys ] = useState([])
  const history = useHistory()

  const [ embassyInfo, setEmbassyInfo ]= useState<IEmbassyDetails>();
  const [pageName, setPageName] = useState(name);
  
  
  useEffect(() => {
    setError(null)
    EmbassyService.getEmbassyInfo(accessToken, cancelToken)
      .then((response) => {
        setEmbassyInfo(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e.response.data.message });
          setError(e.response.data.message);
          setLoading(false);
        }

      });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    if (embassyInfo == null || embassyInfo == undefined) return;
    setPageName(embassyInfo?.embassyName + "'s Patients");
  }, [embassyInfo])

  const [scrollPosition, setScrollPosition] = useScrollPosition("patientList");
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const PAGE_SIZE = 10;

  const getPaginatedValue = (array: any, page: number) => {
    return array.data
  };
  const handleQuery = () => {
    setQuery({
      PageSize: pageSize,
      PageNumber: currentPage,
      Lookback: lookback,
      SearchText: searchText
    })
  }
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  const onHideOrShareClick = (personId: string, providerId: string) => {
    setLoading(true);
    // setError(null)
    PatientService.hideOrSharePatient(personId, providerId, accessToken, cancelToken)
      .then((response) => {
        window.location.reload();
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false);
      });
  };

  const updateRecentSearch = (name: string) => {
    setQuery((q) => ({ ...q, SearchText: name, PageNumber: 1,Lookback:-1 }));
    setLookback(-1)
    setMaxPages(0)
    setPatients(undefined)
    setApiCallCount((c) => c + 1);
    window.scrollTo(0, 0);
    setRecentSearchPatientName(name);
  }
  const clearRecentSearch = () => {
    setRecentSearchPatientName("");
  }

  const onVisitsClick = (patient: IPatient) => {
    setVisits({
      lastVisit: {
        dtm: patient.lastEncounterDtTm,
        location: patient.lastEncounterLoc,
      },
      futureVisits: patient.futureVisits,
    });
    setShowModal(true);
  };
  const removePatient = (patient: IPatient) => {
    setLoading(true);
    PatientService.removePatient(
      {
        patientId: patient.personId,
        patientName: patient.patientName,
        providerId: patient.providerId,
        providerName: patient.physicianName,
        dob: patient.dob
      },
      accessToken
    )
      .then((response) => window.location.reload())
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false);
      });
  };
  const onRemoveClick = (patient: IPatient) => {
    setPatient(patient)
    setLoading(true);
    setShowDeleteModal(true)
  };
  const navigateToPage = (page: number) => {
    setCurrentPage(page);
    setQuery((q) => ({ ...q, PageNumber: page }));
    setPatients(undefined)
    setApiCallCount((c) => c + 1);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    setLoading(true);
    PatientService.getPatients("embassypatients", query, accessToken, cancelToken)
      .then((response) => {
        setLoading(false);
        setValue(response.data.data);
        setPatients(response.data.data);
        if (maxPages == 0) {
          if(response.data.hasNext || query.SearchText !== ""){
            setPages(response.data.totalPages);
            setMaxPages(response.data.totalPages);
          }
          
        }

        setCurrentPage(response.data.currentPage);

      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
          setLoading(false);
        }
        
      });
    return () => {
      cancelToken.cancel();
    };
  }, [apiCallCount]);


  useEffect(() => {
    if (viewAsFilter === "list") {
      if (isBigScreen) {
        setFlag(true);
      }
    } else {
      setFlag(false);
    }
  }, [viewAsFilter]);

  if (error) {
    return (
      <div style={{ margin: "1rem" }}>
        <CustomAlert isSuccessful={false} message={error.message} />
      </div>
    );
  }
  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-end">
        <h2 className="heading-main">{pageName}</h2>
        <div className="mb-2 myPatients-view">
          <span className="p-0 ms-0 me-2">View as</span>
          <ButtonGroup size="sm">
            <ToggleButton
              type="radio"
              value="list"
              name="list"
              checked={viewAsFilter === "list"}
              variant="outline-secondary"
              onClick={() => setViewAsFilter("list")}
            >
              List
            </ToggleButton>
            <ToggleButton
              type="radio"
              value="cards"
              name="cards"
              checked={viewAsFilter === "cards"}
              variant="outline-secondary"
              onClick={() => {
                setViewAsFilter("cards");
              }}
            >
              Cards
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex row justify-content-between">
        <div className="col-sm-5 col-lg-4 col-xl-3 mb-3">
          <LookbackFilter
            onChange={(e) => {
              setLookback(e.target.value)

              setQuery((q) => ({ ...q, Lookback: e.target.value, PageNumber: 1 }));
              setMaxPages(0)
              setPatients(undefined)
              setApiCallCount((c) => c + 1);
              window.scrollTo(0, 0);

            }}
            value={lookback}
            options={visitLookbackOptions}
          />
        </div>
        {loading ? (
          <div className="col loader mb-3">
            <Loader />
          </div>
        ) : null}
        <div className="col-sm-5 col-lg-4 col-xl-3">
          <SearchBar
            placeholder="Search by patient name"
            value={searchText}
            clearRecentSearch={clearRecentSearch}
            source="viewPatients"
            defaultValue={recentSearchPatientName !== "" ? recentSearchPatientName : searchText}
            onSearch={() => {
              setQuery((q) => ({ ...q, SearchText: searchText,PageNumber:1,Lookback:-1 }));
              setLookback(-1)
              setMaxPages(0)
              setPatients(undefined)
              setApiCallCount((c) => c + 1);
              window.scrollTo(0, 0);

            }}

            onTextChange={(text) => {

              if (text == "") {

                
                if(patients !== undefined){
                  setQuery((q) => ({ ...q, SearchText: "",PageNumber:1,Lookback : -1}));
                  setLookback(-1)
                }
                setMaxPages(0)
                setPatients(undefined)
                setApiCallCount((c) => c + 1);
                window.scrollTo(0, 0);
              }
              setSearchText(text)

            }}
          />
        </div>
      </div>
      {patients !== undefined ? (
        <div>
          {patients.length === 0 ? (
            <div style={{ marginTop: "3rem" }}>
              <h4 className="heading-main">No record found</h4>
            </div>
          ) : (
            <div>
              {flag === false ? (
                <PatientsCard
                  patients={patients}
                  source="MyPatients"
                  onHideOrShareClick={(id, pid) => {
                    onHideOrShareClick(id, pid);
                  }}
                  onVisitsClick={onVisitsClick}
                  onRemoveClick={onRemoveClick}
                  updateRecentSearch={updateRecentSearch}
                />
              ) : (
                <PatientsList
                  patients={patients}
                  source="MyPatients"
                  onHideOrShareClick={(id, pid) => {
                    onHideOrShareClick(id, pid);
                  }}
                  onVisitsClick={onVisitsClick}
                  onRemoveClick={onRemoveClick}
                />
              )}
            </div>

          )}
          {patients.length !== 0 ? (
            <div
              className="col d-flex justify-content-center"
              style={{ marginTop: "60px" }}
            >
              <CustomPagination
                currentPage={currentPage}
                totalPages={pages}
                onPageChange={navigateToPage}
                range={Math.max(1, Math.min(5, pages))}
              />
            </div>
          ) : null}
        </div>

      ) : null}

      <div ref={loader} />
      <VisitsModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setVisits(undefined);
        }}
        lastVisit={visits?.lastVisit}
        futureVisits={visits?.futureVisits}
      />
      <DeleteConfirmationModal show={showDeleteModal}
        handleClose={() => {
          setLoading(false)
          setShowDeleteModal(false)
        }}
        handleConfirm={() => {
          patient && removePatient(patient)
          setShowDeleteModal(false)
        }}
      />
    </div>
  );
};

export default EmbassyPatients;

import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IEmbassyDetails from "../types/IEmbassyDetails";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param searchTerm receives the searched name
 * @param token receives the access token of type string
 */
const getEmbassyInfo = async (
    token?: string,
    cancelToken?: CancelTokenSource
  ) => {
    const result = await axios.get<IEmbassyDetails>(
      `${API_BASE_URL}/api/embassy/details/shallow`,
      loginas !== null ? {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
          loginas:loginas !== null ? loginas : JSON.stringify(false),
          email:loginasEmail !== null ? loginasEmail : "",
        },
        cancelToken: cancelToken?.token,
      }:{
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken?.token,
      }
    );
    return result;
  };

const EmbassyService = {
    getEmbassyInfo
};
  
export default EmbassyService;
import { FC, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MButton from "../../../mButton/MButton";
import RecentSearch from "./RecentSearch";
import {
  setRecentSearch,
  getRecentSearch,
} from "../../../../helpers/RecentSearchHelper";
import IPatient from "../../../../types/IPatient";
import "./patients-card.css";

type stateType = {
  state: {
    patient: IPatient;
  };
};



const PatientsCard: FC<{
  patients: IPatient[];
  source: "MyPatients" | "SharedPatients" | "ViewPatients";
  onVisitsClick: (patient: IPatient) => void;
  onHideOrShareClick?: (patientId: string, providerId: string) => void;
  onRemoveClick: (patient: IPatient) => void;
  updateRecentSearch?:(name: string) => void;
}> = ({
  patients,
  source = "ViewPatients",
  onVisitsClick,
  onHideOrShareClick,
  onRemoveClick,
  updateRecentSearch
}) => {
    const history = useHistory<stateType>();

    const onReferPatientClicked = (patient: IPatient) => {
      history.push(`/referrals/refer-a-patient`, { state: { patient } });
    };
    
    const [isEmbassy, setIsEmbassy] = useState<boolean>(false);
    useEffect(() => {
      window.location.href.includes("embassy") ? setIsEmbassy(true) : setIsEmbassy(false);
    }, [])
    const onPatientCardClick = (patient: IPatient) => {
      let searchedItem = {
        name: patient.patientName,
        dob: patient.dob,
      };
      setRecentSearch(searchedItem);
      history.push(`/patients/${patient.personId}`, { state: { patient } });
    };

    return (
      <div id="patients-card" className="row p-1">
        <div className="col-lg-9">
          <div className="row">
            {patients.map((patient, index) => {
              if (patient) {
                return (
                  <div
                    key={patient.personId}
                    className="col-sm-6 col-xl-4 p-2 d-flex align-items-stretch"
                  >
                    <Card className="p-2 social">
                      <Card.Body style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}>
                        <div className="patients-card-title">
                          <div id="link">{patient.patientName}</div>
                        </div>
                        <div className="pt-1 pb-1">
                          <div className="p-0">
                            DOB: <b>{patient.dob}</b>
                          </div>
                          <div>
                            Sex: <b>{patient.sex}</b>
                          </div>
                          <div>
                            MRN: <b>{patient.mrn}</b>
                          </div>
                          {source !== "MyPatients" && (
                            <div className="pb-1">
                              <span className="p-0">
                                PMD: <b>{patient.physicianName}</b>
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="button-container">
                          <MButton
                            onClick={() => onPatientCardClick(patient)}
                            title="View Chart"
                            small
                            variant="accent"
                          />
                          <MButton
                            onClick={() => onVisitsClick(patient)}
                            title="Visits"
                            small
                            variant="secondary"
                          />
                          {isEmbassy == false ? <MButton
                            onClick={() => onReferPatientClicked(patient)}
                            title="Refer"
                            small
                            variant="secondary"
                          /> : null}
                          {source !== "SharedPatients" && isEmbassy == false &&
                            onHideOrShareClick !== undefined && (
                              <>
                                <MButton
                                  onClick={() => onHideOrShareClick(patient.id, patient.providerId)}
                                  title={patient.isShared ? "Hide" : "Share"}
                                  small
                                  variant="secondary"
                                />
                                <MButton
                                  onClick={() => onRemoveClick(patient)}
                                  title="Remove"
                                  small
                                  variant="secondary"
                                />
                              </>
                            )}
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div className="col-lg-3 pt-1">
          <RecentSearch recentSearchList={getRecentSearch()} updateRecentSearch={updateRecentSearch}/>
        </div>
      </div>
    );
  };

export default PatientsCard;

import Roles from "./Roles";
import IRoute from "../types/IRoute";

import Home from "../components/pages/home/Home";
import MyPatients from "../components/pages/myPatients/MyPatients";
import SharedPatients from "../components/pages/myPatients/SharedPatients";
import PracticeAppointments from "../components/pages/myPatients/PracticeAppointments";
import PatientDetails from "../components/pages/myPatients/PatientDetails";
import ViewPatients from "../components/pages/myPatients/ViewPatients";
import NewPatientAccessRequest from "../components/pages/myPatients/NewPatientAccessRequest";
import PatientAccessRequests from "../components/pages/myPatients/PatientAccessRequests";
import NotMyPatientRequest from "../components/pages/myPatients/NotMyPatientRequest";
import PatientAccessRequestsDetails from "../components/pages/myPatients/PatientAccessRequestsDetails";
import PatientAccessRequestsEdit from "../components/pages/myPatients/PatientAccessRequestsEdit";
import MyAppointment from "../components/pages/myPatients/MyAppointment";
import AllAccounts from "../components/pages/allAccounts/AllAccounts";
import AllAccountsListEdit from "../components/pages/allAccounts/AllAccountsListEdit";
import AllAccountsListDetail from "../components/pages/allAccounts/AllAccountsListDetail";
import LoginAsAnotherUser from "../components/pages/allAccounts/LoginAsAnotherUser";
import AccountRequests from "../components/pages/allAccounts/AccountRequests";
import UserPrivacyPolicy from "../components/pages/home/UserPrivacyPolicy";
import TermsOfUse from "../components/pages/home/TermsOfUse";
import SentInvitations from "../components/pages/allAccounts/SentInvitations";
import MyReferrals from "../components/pages/myReferrals/MyReferrals";
import SharedReferrals from "../components/pages/myReferrals/SharedReferrals";
import ViewReferrals from "../components/pages/myReferrals/ViewReferrals";
import NewReferralWizard from "../components/pages/myReferrals/NewReferralWizard";
import MyPractice from "../components/pages/myPractice/MyPractice";
import SliderImages from "../components/pages/portalUpdates/SliderImages";
import AnnouncementBanner from "../components/pages/portalUpdates/AnnouncementBanner";
import AlumniSpotlight from "../components/pages/portalUpdates/AlumniSpotlight";
import DivisionOfTheMonth from "../components/pages/portalUpdates/DivisionOfTheMonth";
import OnlineCMELectures from "../components/pages/portalUpdates/OnlineCMELectures";
import PediatricGrandRounds from "../components/pages/portalUpdates/PediatricGrandRounds";
import PracticeTipOfTheMonth from "../components/pages/portalUpdates/PracticeTipOfTheMonth";
import AmbulatoryCareCenter from "../components/pages/portalUpdates/AmbulatoryCareCenter";
import MemberDirectory from "../components/pages/portalUpdates/MemberDirectory";
import ClinicalCarePathways from "../components/pages/portalUpdates/ClinicalCarePathways";
import CareNetworkTeamContacts from "../components/pages/portalUpdates/CareNetworkTeamContacts";
import MentalHealthResources from "../components/pages/portalUpdates/MentalHealthResources";
import PatientAndFamilyEducationMaterials from "../components/pages/portalUpdates/PatientAndFamilyEducationMaterials";
import NewMemberDirectory from "../components/pages/portalUpdates/components/NewMemberDirectory";
import NewClinicalCarePathways from "../components/pages/portalUpdates/components/NewClinicalCarePathways";
import NewCareNetworkTeamContacts from "../components/pages/portalUpdates/components/NewCareNetworkTeamContacts";
import NewPatientAndFamilyEducationMaterials from "../components/pages/portalUpdates/components/NewPatientFamily";
import NewMentalHealthResources from "../components/pages/portalUpdates/components/NewMentalHealth";
import EditMemberDirectory from "../components/pages/portalUpdates/components/EditMemberDirectory";
import EditClinicalCarePathways from "../components/pages/portalUpdates/components/EditClinicalCarePathways";
import EditCareNetwork from "../components/pages/portalUpdates/components/EditCareNetwork";
import EditPatientFamily from "../components/pages/portalUpdates/components/EditPatientFamily";
import EditMentalHealth from "../components/pages/portalUpdates/components/EditMentalHealth";
import NewFormAnnouncement from "../components/pages/portalUpdates/components/NewFomAnnouncement";
import UploadImages from "../components/pages/portalUpdates/components/UploadImages";
import NewAlumniForm from "../components/pages/portalUpdates/components/NewAlumniForm";
import NewPracticeTipForm from "../components/pages/portalUpdates/components/NewPracticeTipForm";
import NewDivisionForm from "../components/pages/portalUpdates/components/NewDivisionForm";
import NewAmbulatorySchedules from "../components/pages/portalUpdates/components/NewAmbulatorySchedules";
import EditAmbulatory from "../components/pages/portalUpdates/components/EditAmbulatory";
import EditAlumni from "../components/pages/portalUpdates/components/EditAlumni";
import EditAnnouncement from "../components/pages/portalUpdates/components/EditAnnouncement";
import EditPediatric from "../components/pages/portalUpdates/components/EditPediatric";
import EditPracticeTipOfMonth from "../components/pages/portalUpdates/components/EditPracticeTipOfMonth";
import EditOnlineCME from "../components/pages/portalUpdates/components/EditOnlineCME";
import EditDivisionOfMonth from "../components/pages/portalUpdates/components/EditDivisionOfMonth";
import PediatricForm from "../components/pages/portalUpdates/components/PediatricForm";
import NewOnlineCMEForms from "../components/pages/portalUpdates/components/NewOnlineCMEForms";
import PhysicianResources from "../components/pages/physicianResources/PhysicianResources";
import Education from "../components/pages/education/Education";
import PGRInfoWells from "../components/pages/education/PGRInfoWells";
import CMEInfoWells from "../components/pages/education/CMEInfoWells";
import MedicalStaff from "../components/pages/physicianResources/MedicalStaff";
import AdminSection from "../components/pages/admin/AdminSection";
import AdminCreatePractice from "../components/pages/admin/AdminCreatePractice";
import AdminCreatePhysician from "../components/pages/admin/AdminCreatePhysician";
import Resources from "../components/pages/careNetwork/Resources";
import ClinicalCarePathwaysScreen from "../components/pages/careNetwork/ClinicalCarePathwaysScreen";
import CareWebsite from "../components/pages/careNetwork/CareWebsite";
import AccountRequestEdit from "../components/pages/allAccounts/AccountRequestEdit";
import AccountRequestDetails from "../components/pages/myPractice/AccountRequestDetails";
import AdminEditPractice from "../components/pages/admin/AdminEditPractice";
import AdminEditPhysician from "../components/pages/admin/AdminEditPhysician";
import PracticeNotActive from "../components/pages/home/PracticeNotActive";
import PGRVideo from "../components/pages/education/PGRVideo";
import EmbassyPatients from "../components/pages/myPatients/EmbassyPatients";
import MyEmbassy from "../components/pages/myEmbassy/MyEmbassy";

const RouteConfig: IRoute[] = [
  {
    render: Home,
    id: 1,
    path: "/",
    exact: true,
    title: "Home",
    permissions: Roles.ROLES3,
    isShownOnNavBar: true,
  },
  {
    render: EmbassyPatients,
    id: 54,
    path: "/myembassypatients",
    exact: true,
    title: "My Embassy Patients",
    permissions: Roles.ROLES5,
    isShownOnNavBar: true,
  },
  {
    render: MyEmbassy,
    id: 55,
    path: "/myembassy",
    exact: true,
    title: "My Embassy",
    permissions: Roles.ROLES5,
    isShownOnNavBar: true,
  },
  {
    render: UserPrivacyPolicy,
    id: null,
    path: "/home/privacypolicy",
    title: "User Privacy Policy",
    permissions: Roles.ROLES3,
    exact: true,
    isShownOnNavBar: false,
  },
  {
    render: TermsOfUse,
    id: null,
    path: "/home/terms",
    title: "myCHLA Terms of Use",
    permissions: Roles.ROLES3,
    exact: true,
    isShownOnNavBar: false,
  },
  {
    render: PracticeNotActive,
    id: null,
    path: "/practice-not-active",
    title: "Practice Not Active",
    permissions: Roles.ROLES3,
    exact: true,
    isShownOnNavBar: false,
  },
  {
    exact: true,
    title: "My Patients",
    id: 2,
    path: "/login",
    isShownOnNavBar: true,
    permissions: Roles.ROLES3,
    children: [
      {
        render: MyPatients,
        id: 50,
        path: "/patients/mypatients",
        title: "My Patients",
        permissions: Roles.ROLES1,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: SharedPatients,
        id: 12,
        path: "/patients/sharedpatients",
        title: "Shared Patients",
        permissions: Roles.ROLES1,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: ViewPatients,
        id: 13,
        path: "/patients/viewpatients",
        title: "View Patients",
        permissions: Roles.ROLES2,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: NewPatientAccessRequest,
        id: 15,
        path: "/patients/newpatientaccessrequest",
        title: "New Patient Access Request",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: PatientAccessRequests,
        id: 14,
        path: "/patients/patientaccessrequests",
        title: "Patient Access Requests",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: PatientAccessRequestsDetails,
        id: null,
        path: "/patients/patientaccessrequests/details/:id",
        title: "Patient Access Requests",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: PracticeAppointments,
        id: 18,
        path: "/patients/practiceappointments",
        title: "Practice Appointments",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: MyAppointment,
        id: 17,
        path: "/patients/appointments",
        title: "Appointments",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: PatientDetails,
        id: null,
        path: "/patients/:id",
        title: "View Patients",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
    ],
  },
  {
    exact: true,
    title: "My Referrals",
    id: 3,
    path: "/login",
    permissions: Roles.ROLES3,
    isShownOnNavBar: true,
    children: [
      {
        render: MyReferrals,
        id: 51,
        path: "/referrals/myreferrals",
        title: "My Referrals",
        permissions: Roles.ROLES1,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: SharedReferrals,
        id: 20,
        path: "/referrals/sharedreferrals",
        title: "Shared Referrals",
        permissions: Roles.ROLES1,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: ViewReferrals,
        id: 19,
        path: "/referrals/viewreferrals",
        title: "View Referrals",
        permissions: Roles.ROLES2,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: NewReferralWizard,
        id: 21,
        path: "/referrals/newreferral",
        title: "New Referral",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: NewReferralWizard,
        id: null,
        path: "/referrals/re-refer",
        title: "New Referral",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: NewReferralWizard,
        id: null,
        path: "/referrals/refer-a-patient",
        title: "Edit Referral",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: NewReferralWizard,
        id: null,
        path: "/referrals/editreferral",
        title: "Edit Referral",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
    ],
  },
  {
    id: 4,
    path: "/login",
    exact: true,
    title: "My Practice",
    permissions: Roles.ROLES3,
    isShownOnNavBar: true,
    children: [
      {
        render: MyPractice,
        id: 53,
        path: "/practice/mypractice",
        title: "My Practice",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AccountRequests,
        id: 22,
        path: "/practice/accountrequests",
        title: "Account Requests",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AccountRequestDetails,
        id: 22,
        path: "/practice/accountrequests/details/:id",
        title: "Account Request Details",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
    ],
  },
  {
    exact: true,
    title: "Accounts & Access",
    id: 5,
    path: "/login",
    permissions: Roles.ROLES4,
    isShownOnNavBar: true,
    children: [
      {
        render: PatientAccessRequests,
        id: 27,
        path: "/patientaccessrequest/patientaccessrequests",
        title: "Patient Access Requests",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AccountRequests,
        id: 23,
        path: "/accountsandaccess/accountrequests",
        title: "Account Requests",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AccountRequestEdit,
        id: null,
        path: "/accountsandaccess/accountrequests/edit/:id",
        title: "Edit Account Request",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: PatientAccessRequestsEdit,
        id: null,
        path: "/patientaccessrequest/edit/:id",
        title: "Patient Access Requests",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: NotMyPatientRequest,
        id: 28,
        path: "/patientaccessrequest/notmypatients",
        title: "Not My Patient List",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: NotMyPatientRequest,
        id: 23,
        path: "/patientaccessrequest/notmypatients",
        title: "Account Requests",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: SentInvitations,
        id: 24,
        path: "/accountsandaccess/sentinvitations",
        title: "Sent Invitations",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AllAccounts,
        id: 25,
        path: "/account/list",
        title: "All Accounts",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AllAccountsListEdit,
        id: null,
        path: "/account/modify/:id",
        title: "All Accounts",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: AllAccountsListDetail,
        id: null,
        path: "/account/detail/:id",
        title: "All Accounts",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: NotMyPatientRequest,
        id: 26,
        path: "/patientaccessrequest/notmypatients",
        title: "Create Internal Account",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AdminSection,
        id: 47,
        path: "/accounts&access/physicians&practices",
        title: "Physicians & Practices",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: LoginAsAnotherUser,
        id: 49,
        path: "/impersonation/create",
        title: "Login As Another User",
        permissions: Roles.ROLES4,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AdminCreatePractice,
        id: null,
        path: "/accounts&access/physicians&practices/create/practice",
        title: "Create Practice",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: AdminEditPractice,
        id: null,
        path: "/accounts&access/physicians&practices/edit/practice/:id",
        title: "Edit Practice",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },

      {
        render: AdminCreatePhysician,
        id: null,
        path: "/accounts&access/physicians&practices/create/physician",
        title: "Create Physician",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: AdminEditPhysician,
        id: null,
        path: "/accounts&access/physicians&practices/edit/physician/:id",
        title: "Edit Physician",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
    ],
  },
  {
    exact: true,
    title: "Portal Updates",
    id: 7,
    path: "/login",
    isShownOnNavBar: true,
    permissions: Roles.ROLES3,
    children: [
      {
        render: SliderImages,
        id: 29,
        path: "/portalupdates/sliderimages",
        title: "Slider Images ",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },

      {
        render: AnnouncementBanner,
        id: 30,
        path: "/portalupdates/announcementbanner",
        title: "Announcements",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AlumniSpotlight,
        id: 31,
        path: "/portalupdates/alumnispotlight",
        title: "Alumni Highlights",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: PediatricGrandRounds,
        id: 32,
        path: "/portalupdates/pediatricgrandrounds",
        title: "Pediatric Grand Rounds",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: OnlineCMELectures,
        id: 33,
        path: "/portalupdates/onlinecmelectures",
        title: "Online CME Lectures",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: PracticeTipOfTheMonth,
        id: 34,
        path: "/portalupdates/practicetipofthemonth",
        title: "Practice Tip Of The Month",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: DivisionOfTheMonth,
        id: 35,
        path: "/portalupdates/divisionofthemonth",
        title: "Division Of The Month",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: AmbulatoryCareCenter,
        id: 36,
        path: "/portalupdates/ambulatorycarecenter",
        title: "Ambulatory Schedules",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: MemberDirectory,
        id: 41,
        path: "/portalupdates/memberdirectory",
        title: "Member Directory",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: ClinicalCarePathways,
        id: 42,
        path: "/portalupdates/clinicalcarepathways",
        title: "Clinical Care Pathways",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: CareNetworkTeamContacts,
        id: 43,
        path: "/portalupdates/carenetworkteamcontacts",
        title: "Care Network Team Contacts",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: MentalHealthResources,
        id: 45,
        path: "/portalupdates/mentalhealthresources",
        title: "Mental Health Resources",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: PatientAndFamilyEducationMaterials,
        id: 46,
        path: "/portalupdates/patientandfamilyeducationmaterials",
        title: "Patient And Family Education Materials",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: NewFormAnnouncement,
        id: null,
        path: "/portalupdates/components/newformannouncement",
        title: "New Form Announcement",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: NewMemberDirectory,
        id: null,
        path: "/portalupdates/newmemberdirectory",
        title: "New Member Directory",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: NewClinicalCarePathways,
        id: null,
        path: "/portalupdates/newclinicalcarepathways",
        title: "New Clinical Care Pathways",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: NewCareNetworkTeamContacts,
        id: null,
        path: "/portalupdates/newcarenetworkteamcontact",
        title: "New Care Network Team Contacts",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: NewMentalHealthResources,
        id: null,
        path: "/portalupdates/newmentalhealthresources",
        title: "New Mental Health Resources",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: NewPatientAndFamilyEducationMaterials,
        id: null,
        path: "/portalupdates/newpatientandfamilyeducationmaterials",
        title: "New Patient And Family Education Materials",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: EditMemberDirectory,
        id: null,
        path: "/portalupdates/editmemberdirectory",
        title: "Edit Member Directory",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: EditClinicalCarePathways,
        id: null,
        path: "/portalupdates/editclinicalcarepathways",
        title: "Edit Clinical Care Pathways",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: EditCareNetwork,
        id: null,
        path: "/portalupdates/editcarenetworkteamcontact",
        title: "Edit Care Network Team Contacts",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: EditMentalHealth,
        id: null,
        path: "/portalupdates/editmentalhealthresources",
        title: "Edit Mental Health Resources",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: EditPatientFamily,
        id: null,
        path: "/portalupdates/editpatientandfamilyeducationmaterials",
        title: "Edit Patient And Family Education Materials",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: true,
      },
      {
        render: EditAnnouncement,
        path: "/portalupdates/components/editformannouncement",
        title: "Edit Announcement Form",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
        id: null,
      },
      {
        render: NewAlumniForm,
        id: null,
        path: "/portalupdates/components/newalumniform",
        title: "New Alumni Form",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: EditAlumni,
        path: "/portalupdates/components/editalumniform",
        title: "Edit Alumni Form",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
        id: null,
      },
      {
        render: NewPracticeTipForm,
        id: null,
        path: "/portalupdates/components/newpracticetipform",
        title: "New Practice Tip Form",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: EditPracticeTipOfMonth,
        path: "/portalupdates/components/editpracticetipform",
        title: "Edit Practice Tip Form",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
        id: null,
      },
      {
        render: NewDivisionForm,
        id: null,
        path: "/portalupdates/components/newdivisionform",
        title: "New Division Form",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: EditDivisionOfMonth,
        path: "/portalupdates/components/editdivisionform",
        title: "Edit Division Form",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
        id: null,
      },
      {
        render: NewAmbulatorySchedules,
        id: null,
        path: "/portalupdates/components/newambulatoryschedules",
        title: "New Ambulatory Schedules",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: EditAmbulatory,
        path: "/portalupdates/components/editambulatory",
        title: "Edit Ambulatory",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
        id: null,
      },
      {
        render: PediatricForm,
        id: null,
        path: "/portalupdates/components/newpediatricform",
        title: "New Pediatric Grand Round",
        variant: "NewPediatricForm",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: PediatricForm,
        path: "/portalupdates/components/editpediatric",
        title: "Edit Pediatric Grand Rounds",
        variant: "EditPediatricForm",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
        id: null,
      },
      {
        render: NewOnlineCMEForms,
        id: null,
        path: "/portalupdates/components/newonlineCMEforms",
        title: "New Online CME",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: EditOnlineCME,
        path: "/portalupdates/components/editonlineCME",
        title: "Edit Online CME",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
        id: null,
      },
      {
        render: UploadImages,
        id: null,
        path: "/portalupdates/components/uploadimages",
        title: "Upload Images",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
    ],
  },
  {
    render: PhysicianResources,
    id: 9,
    path: "/physicianresources",
    exact: true,
    title: "Physician Resources",
    permissions: Roles.ROLES3,
    isShownOnNavBar: true,
    children: [
      {
        render: MedicalStaff,
        id: null,
        path: "/physicianresources/medicalstaff",
        title: "Medical Staff Services",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
    ],
  },
  {
    render: Education,
    id: 11,
    path: "/login",
    exact: true,
    title: "Care Network",
    permissions: Roles.ROLES3,
    isShownOnNavBar: true,
    children: [
      {
        render: Resources,
        id: 40,
        path: "/carenetwork/resources",
        title: "Resources",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: ClinicalCarePathwaysScreen,
        id: null,
        path: "/carenetwork/clinicalcarepathwaysscreen",
        title: "Clinical Care Pathways",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: CareWebsite,
        id: 52,
        path: "/carenetwork/carewebsite",
        title: "Care Network Website",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
    ],
  },
  {
    render: Education,
    id: 10,
    path: "/education",
    exact: true,
    title: "Education",
    permissions: Roles.ROLES3,
    isShownOnNavBar: true,
    children: [
      {
        render: PGRInfoWells,
        id: null,
        path: "/education/pgrinfowells",
        title: "Pediatric Grand Rounds",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: PGRVideo,
        id: null,
        path: "/education/pgrinfowells/:id",
        title: "Pediatric Grand Rounds",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
      {
        render: CMEInfoWells,
        id: null,
        path: "/education/cmeinfowells",
        title: "Online CME Lectures",
        permissions: Roles.ROLES3,
        exact: true,
        isShownOnNavBar: false,
      },
    ],
  },
];

export default RouteConfig;

import { FC, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import PracticeService from "../../../services/PracticeService";
import { appInsights } from "../../../appInsights";
import axios, { AxiosError } from "axios";
import Loader from "../../common/Loader";
import usePracticeInfo from "../../../hooks/usePracticeInfo";
import useUserInfo from "../../../hooks/useUserInfo";
import IPracticeInfo from "../../../types/IPracticeInfo";
import { OKTA_AUTH_CONFIG } from "../../../config";
import CustomAlertModal from "../../common/CustomAlertModal";
import { Form } from "react-bootstrap";
import IEmbassyDetails from "../../../types/IEmbassyDetails";

const MyEmbassyInfo: FC<{
  onPracticeInfoUpdate?: (practice: IEmbassyDetails) => void;
  practiceInfo:IEmbassyDetails | undefined
}> = ({ onPracticeInfoUpdate,practiceInfo }) => {
  // const [practiceInfo, setPracticeInfo] = usePracticeInfo();
  const userInfo = useUserInfo();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null)




  const redirectOKTA = () => {
    let email:any = userInfo?.email;
    let isInternal;
    if (/^[a-zA-Z0-9._-]+@chla\.usc\.edu$/.test(email)) {
      isInternal = true;
   } 

   
    if (isInternal) {
      window.open("https://oktalogin.chla.org/enduser/settings");
    } else {
      window.open(
        OKTA_AUTH_CONFIG.issuer
          ? OKTA_AUTH_CONFIG.issuer.split("/oauth2")[0]
          : "https://extoktalogin.chla.org/enduser/settings"
      );
    }
  };

  return (
    <div>
      {loading && (
        <div style={{ marginBottom: 10 }}>
          <Loader />
        </div>
      )}
      {practiceInfo !== undefined && (
        <>
          <h5 className="mt-2 mb-3" id="my-practice-purple-text">
            Embassy ID: {practiceInfo?.embassyID}
          </h5>
          {practiceInfo.embassyAddress1 !== null && <><p className="m-0">{practiceInfo?.embassyName}</p>
            <p className="m-0">{practiceInfo?.embassyAddress1}</p>
            <p className="m-0">{practiceInfo?.embassyAddress2}</p>
            <p className="m-0">
              {practiceInfo?.embassyCity}, {practiceInfo?.embassyState},{" "}
              {practiceInfo?.embassyZip}
            </p></>}
          <p className="mt-3 mb-1">Moving?</p>
          <ul>
            <li>Is your practice moving?</li>
            <li>Are you leaving this practice?</li>
            <li>Are you part of another practice or joining a new practice?</li>
          </ul>
          <a id="link" className="about" href="mailto:help@chla.usc.edu">Tell us about it</a>
          <h5 className="mt-3 font-weight-normal my-practice-account "  >
            myCHLA Account
          </h5>
          <p>
            Update your account information or change your password{" "}
            <a id="link" className="about" style={{cursor:'pointer'}} onClick={redirectOKTA} target="_blank">here</a>
          </p>
        </>
      )}
      <CustomAlertModal
        show={error !== null}
        handleClose={() => {
          setError(null)
        }}
        header={"Error!"}
        // @ts-ignore
        body={error?.response?.data.message || "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support."}
      />
    </div>
  );
};

export default MyEmbassyInfo;
